import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refItemListTable = ref(null)

  const perPage = ref(5)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryFilter = ref(null)
  const employeeData = JSON.parse(localStorage.getItem('employeeData'))
  const branchFilter = ref(parseInt(employeeData.branch_id, 10))

  const dataMeta = computed(() => {
    const localItemsCount = refItemListTable.value ? refItemListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refItemListTable.value.refresh()
  }

  watch([currentPage, perPage, categoryFilter, branchFilter], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    store
      .dispatch('item/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        category_id: categoryFilter.value,
        branch_id: branchFilter.value,
      })
      .then(response => {
        const { items, total } = response.data.data

        callback(items)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting item list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refItemListTable,

    refetchData,

    // Filters
    categoryFilter,
    branchFilter,
  }
}
